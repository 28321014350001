import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import mixpanel from 'mixpanel-browser'
import { navigate } from 'gatsby'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import { isSignedIn } from '../lib/auth'

const TemplateWrapper = ({ children, requireAuth, ...navbarProps }) => {
  useEffect(() => {
    // how to actually do checking of the token?
    // If its not valid we want to sign people out
    if (requireAuth && !isSignedIn()) {
      navigate(
        `/login?back_to_url=${
          (typeof window !== 'undefined' && window.location.pathname + window.location.search) || ''
        }`,
        {
          replace: true,
        }
      )
    }
  })
  const { title, description } = useSiteMetadata()
  return (
    <div style={{ height: '100vh' }} className="fullPage">
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        {/*<link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />*/}
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <link rel="stylesheet" type="text/css" href={`${withPrefix('/')}fullPage.css`} />

        {/*<link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />*/}
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix('/')}img/pulse-logo-whitetext.png`} />
      </Helmet>
      {children}
    </div>
  )
}

export default TemplateWrapper
